@import "./mixin.scss";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background-color: $beige;
}

h5 {
    font-size: 20px;
    color: white;
    text-transform: uppercase;
    transform: rotate(90deg);
    margin-top: -2px;
    position: relative;
    font-family: "Black";
}
a {
    all: unset;
    text-decoration: none;
}
h1 {
    font-size: 20px;
    font-family: "Black";
    font-weight: 900;
    letter-spacing: 2.5px;
    @include mobile {
        font-size: 16px;
    }

}

h2 {
    color: white;
    font-family: "Regular";
    font-weight: 400;
    font-size: 26px;
    line-height: 30px;
    b {
        font-family: "Bold";
        font-weight: 700;
        @include mobile {
            letter-spacing: 0;
            font-size: 18px;
            line-height: 18px;
        }
    }
    @include mobile {
        line-height: 22px;
        font-size: 26px;
    }
}

h3 {
    font-family: 'Regular';
    font-size: 34px;
    font-weight: 200;
    @include mobile {
        font-size: 24px;
    }
}

h4{
    font-family: 'Regular';
    font-size: 24px;
    letter-spacing: 0;
    line-height: 34px;
    @include mobile{
        line-height: 28px;
    }
}
p {
    font-family: 'Regular';
}
h6{
    font-family: 'Bold';
    font-size: 22px;
}
.title {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    color: white;
    position: absolute;
    top: 3rem;
    left: 3rem;
    img{
        padding-left: 1rem;
    }
    @include mobile {
        display: none;

    }
}

.border {
    padding: 3rem 0 0 3rem;
}

.underline {
    text-transform: uppercase;
    text-decoration: underline;
    padding-bottom: 1rem;
    text-underline-offset: 1rem;
    text-decoration-thickness: 2px;
}

.emerald {
    color: $emerald;
}

@font-face {
    font-family: "Regular";
    src: url("../../public/fonts/NeuzeitGro-Reg.woff2") format("woff2"),
        url("../../public/fonts/NeuzeitGro-Reg.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Bold";
    src: url("../../public/fonts/NeuzeitGro-Bol.woff2") format("woff2"),
        url("../../public/fonts/NeuzeitGro-Bol.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Black";
    src: url("../../public/fonts/NeuzeitGro-Bla.woff2") format("woff2"),
        url("../../public/fonts/NeuzeitGro-Bla.woff") format("woff");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}


.desktop {
    display: flex;
    @include mobile {
        display: none;
    }
}

.mobile {
    display: none;
    @include mobile {
        display: flex;
    }
}

.hero-image{
    height: 100vh;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    
    position: relative;
    @include mobile {
        height: auto;
    }
}
.contain{
    width: min(100%, 1600px);
}
.contain-wrapper {
    width: 100%;
    display: flex;

    position: relative;
    flex-direction: column;
    align-items: center;
}

.hero-arrow {
    position: absolute;
    bottom: 2rem;
    left: 50%;
    z-index: 2;
    animation: floating 2s ease-in-out infinite;
    @include mobile {
        display: none;
    }
}

@keyframes floating {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0);
    }
}

