@import "../../scss/global.scss";

.where.pad {
    padding: 0 45px 0 90px;
    position: relative;
    width: 100%;
    @include mobile {
        padding: 105px 0 0 0;
    }

    .hero {
        width: 100%;
        position: relative;
        background-color: $paleGreen;
        overflow: hidden;
        height: 100vh;
        @include mobile {
            height: auto;
        }
        .splash {
            display: flex;
            justify-content: space-between;
            background-image: url("../../../public/images/where/backs/mask.png");
            background-size: cover;
            background-repeat: no-repeat;

            .intro-text {
                padding: 0 0rem 0 3rem;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 40%;
            }
            @include mobile {
                flex-direction: column;

                .intro-text {
                    padding: 5rem 1.5rem 3rem 1.5rem;
                    width: 100%;
                }
                img {
                    width: 100%;
                }
            }
        }
    }

    .text-wrapper {
        display: flex;
        align-items: center;
        h4.emerald-copy {
            width: 40%;
            font-size: 20px;
            color: $emerald;
            padding: 5rem 3rem;
            @include mobile {
                width: 100%;
                padding: 3rem 1.5rem;
            }
        }
    }
}

.amenities {
    position: relative;
    padding: 4.5rem 3rem 3rem 3rem;
    @include mobile {
        padding: 3rem 1.5rem 0 1.5rem;
    }
    h3 {
        font-size: 34px;
        @include mobile {
            font-size: 24px;
        }
    }
    .flex-box {
        padding: 3rem 0 0 0;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @include mobile {
            padding: 1.5rem 0 0 0;
        }
        div {
            width: 30%;
            min-width: 250px;
            padding-bottom: 3rem;
            @include mobile {
                width: 100%;
            }
            h6 {
                max-width: 100%;
                font-size: 22px;
                padding-bottom: 0.5rem;
            }
            p {
                font-size: 18px;
                @include mobile {
                    font-size: 16px;
                }
            }
        }
    }
}

.gallery {
    display: flex;
    justify-content: start;
    align-items: start;
    width: 100%;
    padding: 3rem 0;
    .right-row {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: start;
        width: 18%;
        img {
            padding-top: 1rem;
        }
    }

    .flex-space {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: 75%;
        .first-row {
            display: flex;

            justify-content: space-between;
            img {
                width: 100%;
                padding-top: 1rem;
                padding-right: 1rem;
                height: auto;
            }
        }
    }
}

.map-section {
    display: flex;
    padding-bottom: 3rem;
    .map-image {
        max-width: 60%;
    }
    @include mobile {
        flex-direction: column;
        .map-image {
            max-width: none;
            width: 100%;
            padding-bottom: 1.5rem;
        }
    }
}

.diagonal::after {
    content: " ";
    width: 0;
    height: 0;
    border-top: 20vh solid transparent;
    border-left: calc(100vw - 90px) solid $paleGreen;
    @include mobile {
        border-top: 5vh solid transparent;
        border-left: calc(100vw) solid $paleGreen;
    }
    display: flex;
}

.title-wrapper {
    display: flex;
}

.map-locations {
    display: flex;
    padding: 0.5rem 1.5rem;
    @include mobile {
        padding: 1.5rem 1.5rem 0 1.5rem;
    }
    flex-wrap: wrap;
    justify-content: flex-start;
    .flex {
        display: flex;
        justify-content: flex-start;
        padding: 0.5rem 0;
        align-items: center;
        width: 50%;
        p {
            font-size: 12px;
        }
        p:first-child {
            background-color: $paleGreen;
            margin-right: 1rem;
            padding: 5px 5px 2px 5px;
            color: white;
        }
        p:nth-child(2) {
            font-family: "Bold";
            text-transform: uppercase;
            color: $emerald;
        }
    }
}


.amen-back{
    position: absolute;
    top: -100%;
    right: 0;
}

.mb-amenities {
    h3 {
        padding: 1.5rem 1.5rem 3rem 1.5rem;
    }
}

.zoom-container {
    overflow: hidden;
    position: relative;
  }
  
  .zoom-container img {
    transition: transform 0.3s ease-in-out;
  }
  
  .zoom-container:hover img {
    transform: scale(1.2); /* Adjust zoom level as needed */
  }
  #zoom-image {
    transition: transform 0.3s ease-in-out;
  }