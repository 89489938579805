@import "../../scss/global.scss";

.icons {
    display: flex;
    height: 100%;
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
    flex-wrap: wrap;
    align-items: center;
    .icon {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        
        .img-wrapper{
            display: flex;
            height: 80px;
        }
        @include mobile {
            padding: 1.5rem 0;
        }
        p {
            padding-top: 1.5rem;
            font-size: 20px;
            color: $emerald;
            font-family:"Regular";
            letter-spacing: 0;
            line-height: 26px;
            @include mobile {
                font-size: 16px;
            }
        }
    }
}
