$beige: #ece6d5;
$brown: #c07645;
$orange: #C07645;
$yellow: #ffb51f;
$footerYellow: #FFD626;
$paleGreen: #9bb4b0;
$emerald: #06413C;
$blue: #192EBB;

@mixin large-desktop {
    @media (min-width: 1500px) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: 1200px) {
        @content;
    }
}

@mixin small-desktop {
    @media (min-width: 1024px) and (max-width: 1200px) {
        @content;
    }
}

@mixin tablet {
    @media screen and (min-width: 768px) and (max-width: 1023px) {
        @content;
    }
}

@mixin mobile {
    @media screen and (max-width: 1024px) {
        @content;
    }
}
