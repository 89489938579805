@import "../../scss/mixin.scss";

.footer {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: $paleGreen;
    position: relative;
    padding: 0 1.5rem 3rem 1.5rem;
    @include mobile {
        padding: 1.5rem 1.5rem 1.5rem 1.5rem;
        justify-content: center;
        align-items: center;
    }
    h3 {
        color: white;
    }
    .buttons {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        flex-wrap: wrap;
        @include mobile {
            width: 100%;
            flex-direction: column;
            align-items: center;
        }
        .download-btn {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 150px;
            z-index: 2;
            p {
                font-size: 16px;
                font-family: "Bold";
                line-height: 1em;
                @include mobile {
                    margin-bottom: -4px;
                }
            }
            img {
                height: 1em;
                display: block;
                padding-left: 1rem;
            }
            margin: 1rem 3rem 1rem 0;
            padding: 1rem 1.5rem;
            background-color: $beige;
            color: $paleGreen;
            align-items: flex-end;
            @include mobile {
                margin: 1rem 0;
                padding: 1rem 1.5rem;
                background-color: $beige;
                color: $paleGreen;
                align-items: flex-end;
            }
        }
        .download-btn.contact {
            border: 1px solid $beige;
            background-color: $paleGreen;
            color: $beige;
        }

        .download-btn:hover {
            p {
                text-decoration: underline;
            }
        }
    }
}

.mask-footer {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 150%;
    @include mobile {
        width: 50%;
    }
}

.trans {
    background-color: transparent;
}
