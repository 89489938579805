@import "../../scss/global.scss";

.highlight-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: flex-start;
    justify-content: center;
    padding: 3rem 0;
    .highlight {
        height: 100%;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        text-align: center;
        min-width: 200px;
        padding: 1.5rem;
        @media (max-width: 1200px) {
            flex-basis: 33%;
        }
        h4 {
            padding-top: 1.5rem;
            font-size: 18px;
            max-width: 85%;
            text-transform: uppercase;
            color: $emerald;
            font-family: "Bold";
            line-height: 22px;
            @include mobile {
                font-size: 16px;
                max-width: 100%;
                line-height: 22px;
            }
        }
        .img-wrapper {
            display: flex;
            justify-content: center;
            height: 100px;
            img {
                max-height: 100%;
                @include mobile {
                    width: 90%;
                }
            }
        }
    }
}
