@import '../../scss/global.scss';

.locationSpec{
    padding: 1.5rem 3rem;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    img{
        padding-right: 1rem;
    }
    @include mobile {
        padding: 1.5rem 1.5rem;
    }
    .loc{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 30%;
        min-width: 300px;
        padding-bottom: 3rem;
        .spec-title {
            display: flex;
            align-items: center;
            color: $orange;
            font-family: 'Regular';
            font-size: 28px;
            letter-spacing: 2.14px;
            line-height: 37px;
            text-transform: uppercase;
            @include mobile {
                font-size: 24px;
            }
        }
        
        .copy{
            h6{
                padding: 0rem 0 1rem 0;
                font-size: 22px;
                color: $emerald;
                text-transform: uppercase;
                font-family: 'Bold';
            }   
        }

    }    

}    
