@import "../../scss/global.scss";

.nav {
    width: 45px;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: fixed;
    z-index: 2;
    cursor: pointer;
    a.link {
        cursor: pointer;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        h5{
            cursor: pointer;

            width: 50px;
            line-height: 1em;
            margin-left: -2px;
        }
        img {
            cursor: pointer;

            padding-bottom: 5px;
            margin-top: 3rem;
        }
    }
}

.nav.home {
    background-color: $beige;
    display: flex;
    padding-top: 3rem;
    left: 0;
}
.nav.what {
    background-color: $brown;
    padding-top: 3.8rem;
    left: 45px;
}
.nav.where {
    background-color: $paleGreen;
    padding-top: 3.8rem;
    left: 90px;
}
.nav.who {
    background-color: $yellow;
    padding-top: 3.8rem;
    left: 135px;
}

.mobile-nav {
    @include mobile {
        display: flex;
        position: fixed;
        top: 0;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        z-index: 5;
        .top-holder {
            padding: 1.5rem;
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;
            background-color: $beige;
            position: relative;
            z-index: 2;
        }
        .links {
            width: 100%;
            display: flex;
            flex-direction: column;
            .link {
                padding: 0.5rem 1.5rem;
                color: white;
                cursor: pointer;
                &:first-child {
                    background-color: $brown;
                }
                &:nth-child(2) {
                    background-color: $paleGreen;
                }
                &:nth-child(3) {
                    background-color: $yellow;
                }
                h1:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    .burger {
        display: flex;
        justify-content: space-between;
        .burger-line {
            width: 2px;
            height: 30px;
            background-color: $paleGreen;
            margin: 5px 7px;
        }
    }
}

.mobile-nav.scrolled {
    .top-holder {
        img {
            height: 20px;
        }
        padding: 5px 1.5rem;
    }
    .burger {
        cursor: pointer;
        .burger-line {
            width: 2px;
            height: 30px;
            background-color: $paleGreen;
        }
    }
}
.link-content {
    display: flex;
    align-items: flex-start;
    h1 {
        width: 20%;
    }
    img {
        height: 15px;
    }
}

.nav:hover {
    a.link {
        img {
            transform: translateY(10px);
            transition: transform 0.3s ease;
        }
    }
}