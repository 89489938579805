@import "../../scss/global.scss";


.custom-marker {
    position: absolute;
    transform: translate(-50%, -100%); /* This positions the marker's anchor at its bottom center */
  }

.who.pad {
    padding: 0 0 0 135px;
    position: relative;
    width: 100%;
    @include mobile {
        padding: 105px 0 0 0;
    }

    .hero {
        width: 100%;
        position: relative;
        background-color: $yellow;
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        height: 100vh;
        @include mobile {
            height: auto;
        }
        .splash {
            display: flex;
            justify-content: space-between;
            padding: 0 3rem;
            background-image: url("../../../public/images/who/backs/mask.png");
            background-repeat: no-repeat;
            background-size: auto 50vh;
            .contacts {
                display: flex;
                justify-content: space-evenly;
                flex-direction: column;
                h2.emerald {
                    font-size: 26px;

                    @include mobile {
                        color: white;
                        padding: 3rem 0rem 1.5rem 0rem;
                    }
                }
                .flex-wrapper {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                }
                .flex-box {
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;
                    width: 100%;
                    align-items: flex-start;
                    .agent {
                        padding: 10px 0;
                        color: $emerald;

                        a {
                            cursor: pointer;
                            p {
                                text-decoration: underline;
                            }
                        }
                        p {
                            line-height: 25px;
                        }
                    }
                    .img-wrapper {
                        height: 100px;
                        display: flex;
                    }
                }
            }
            @include mobile {
                flex-direction: column;
                padding: 3rem 0rem;
                justify-content: flex-start;
                .contacts {
                    padding: 0 1.5rem;
                    .flex-wrapper {
                        display: none;
                    }
                }
            }
        }
        @include mobile {
            flex-direction: column;
        }
    }
}
.misrep {
    padding: 3rem 3rem 5rem 3rem;
    background-color: $beige;
    @include mobile {
        padding: 3rem 1.5rem;
    }
    img {
        padding-bottom: 1.5rem;
    }
    p {
        color: $emerald;
        font-size: 11px;
    }
    hr {
        margin: 1.5rem 0;
        color: $emerald;
    }
}

.who.pad .map-tri {
    content: " ";
    border-right: 20vh solid transparent;
    border-top: calc(100vh) solid $yellow;
    position: absolute;
    z-index: 20;
    @include mobile {
        border-top: 5vh solid $yellow;
        border-right: calc(100vw) solid transparent;
    }
}

.map {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 4;
    @include mobile {
        width: 100%;
        height: 50vh;
    }
}

.marker {
    object-position: 0 0;
    object-fit: cover;
}

.agents {
    @include mobile {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 50%;
        padding: 3rem 1.5rem 0 1.5rem;

        img {
            padding-bottom: 1.5rem;
            max-height: 100px;
        }
        .flex-box {
            padding: 0.75rem 0 1.5rem 0;
        }

        .agent {
            padding: 0.75rem 0;
            p {
                font-size: 16px;
                font-family: "Regular";
                font-weight: 400;
                letter-spacing: 0;
                line-height: 20px;
                color: $emerald;
            }
            a {
                color: $emerald;
                text-decoration: underline;
            }
        }
    }
}

.misrep-wrapper::after {
    content: " ";
    width: 0;
    height: 0;
    border-top: 10vh solid transparent;
    border-left: calc(100vw - 135px) solid $paleGreen;
    @include mobile {
        border-top: 10vh solid transparent;
        border-left: calc(100vw) solid $paleGreen;
    }
    display: block;
}
