@import "../../scss/global.scss";

.whats.pad {
    padding: 0 90px 0 45px;
    position: relative;
    width: 100%;
    @include mobile {
        padding: 105px 0 0 0;
    }
    .hero {
        width: 100%;
        position: relative;
        background-color: $brown;
        overflow: hidden;
        height: 100vh;
        @include mobile {
            height: auto;}
        .splash {
            display: flex;
            justify-content: space-between;
            background-image: url("../../../public/images/what/mask.png");
            background-size: auto 100vh;
            background-repeat: no-repeat;

            .intro-text {
                padding: 0 0 0 3rem;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                width: 40%;
                @include mobile {
                    padding: 3rem;
                }
            }

            @include mobile {
                flex-direction: column;

                .intro-text {
                    width: 90%;
                    padding: 5rem 1.5rem 3rem 1.5rem;
                }
                
                img {
                    padding: 0;
                    width: 100%;
                }
            }
        }
    }
}

.text-wrapper {
    display: flex;
    align-items: center;
    @include mobile {
        flex-direction: column;
    }
    .emerald-copy {
        width: 40%;
        font-size: 20px;
        color: $emerald;
        padding: 5rem 1.5rem;
        font-family: "Regular";
        font-weight: 400;
        @include mobile {
            width: 100%;
            &:first-child {
                padding: 3rem 1.5rem 1.5rem 1.5rem;
            }
            &:nth-child(2) {
                padding: 1.5rem 1.5rem 3rem 1.5rem;
            }
        }
    }
}

.key-hi {
    background-color: $paleGreen;
    display: flex;
    align-items: center;
    height: 100%;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    position: relative;
    h3 {
        color: white;
        font-size: 34px;
        font-family: "Regular";
        font-weight: 400;
        @include mobile {
            font-size: 24px;
            padding-top: 3rem;
        }
    }
}

.key-hi.triangles {
    &:before {
        content: " ";
        width: 0;
        height: 0;
        border-bottom: 20vh solid transparent;
        border-left: 100vw solid $beige;
        display: flex;
        @include mobile {
            border-bottom: 5vh solid transparent;
        }
    }
    &:after {
        content: " ";
        width: 0;
        height: 0;
        border-top: 20vh solid transparent;
        border-left: 100vw solid $beige;
        display: flex;
        @include mobile {
            border-top: 5vh solid transparent;
        }
    }
}

.key-hi.second {
    background-color: $beige;
    h3 {
        color: $emerald;
    }
}

.floorplan {
    display: flex;
    justify-content: space-between;
    padding: 3rem;
    background-color: white;
    @include mobile {
        flex-direction: column;
        background-image: none;
        justify-content: center;
        align-items: center;
        width: 100%;
        text-align: center;
    }
    h3 {
        color: $emerald;
    }
    p {
        color: $emerald;
        padding: 0.5rem 0 1rem 0;
        font-size: 20px;
        @include mobile {
            font-size: 18px;
            padding: 1rem 0 3rem 0;
        }
    }
    .flex-box {
        display: flex;
        align-items: center;
        padding: 0rem 0;
        @include mobile {
            display: none;
        }
        h4 {
            font-size: 16px;
        }
        img {
            padding: 0 1rem 0 0;
            @include mobile {
                padding: 3rem 0;
            }
        }
    }
   
}
.key{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    .mb_flex{
           display: flex;
           justify-content: flex-start;
           width: 100%;
           align-items: flex-start;
           img{
            height: 1.5em;
           }
           h4{
            font-size: 16px;
            padding-left: 1em;
           }
       }

}

.floorplan-wrapper {
    background-color: white;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}
.floorplan-wrapper::after {
    content: " ";
    width: 0;
    height: 0;
    border-bottom: 20vh solid $paleGreen;
    border-right: calc(100vw - 90px) solid white;
    @include mobile {
        border-bottom: 5vh solid $paleGreen;
        border-right: calc(100vw) solid white;
    }
    display: flex;
}


.mask{
    position: absolute;
    bottom: 0;
    left: 0;
}
